import { useMemo } from 'react';
import { useBrazeContext } from '../initialization';
import { CustomContentCardData } from '../types';
import { EmbeddedContentCard } from 'features/braze/embedded-content-cards/EmbeddedContentCard';
import { EmbeddedContentBanner } from 'features/braze/embedded-content-cards/EmbeddedContentBanner';

export enum EmbeddedContentCardPurposes {
  LOAD_PAY = 'load_pay'
}

export enum EmbeddedContentCardLocations {
  SUGGESTED_LOADS = 'suggested_loads',
  ACCOUNTS_RECEIVABLE = 'accounts_receivable',
  MY_LOADS_BILLING_TAB = 'my_loads_billing_tab'
}

export enum EmbeddedContentCardTypes {
  CARD = 'card',
  BANNER = 'banner'
}

export interface EmbeddedContentCardFeedProps {
  location: EmbeddedContentCardLocations;
  purpose: EmbeddedContentCardPurposes;
  type: EmbeddedContentCardTypes;
}

export const EmbeddedContentCardFeed = ({ location, purpose, type }: EmbeddedContentCardFeedProps) => {
  const { contentCards, handleCardImpression, requestFeedRefresh } = useBrazeContext();

  const triggerClickEvent = (card: CustomContentCardData) => {
    handleCardImpression(card);
    requestFeedRefresh();
  };

  const pageContentCard = useMemo(() => {
    return (contentCards ?? []).find((card: CustomContentCardData) => {
      const isSelectedpurpose = card.extras.purpose === purpose;
      const isSelectedLocation = card.extras.location?.includes(location);
      return isSelectedpurpose && isSelectedLocation;
    });
  }, [contentCards]);

  if (!pageContentCard) {
    return null;
  }

  switch (type) {
    case EmbeddedContentCardTypes.CARD:
      return <EmbeddedContentCard contentCard={pageContentCard} triggerClickEvent={triggerClickEvent} />;
    case EmbeddedContentCardTypes.BANNER:
      return <EmbeddedContentBanner contentCard={pageContentCard} triggerClickEvent={triggerClickEvent} />;
    default:
      return null;
  }
}