export const IconBox = {
  background: 'linear-gradient(90deg, #014886 0%, #01A1DF 100%)',
  height: '32px',
  width: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  marginTop: '4px !important'
}

export const EmbeddedContentCardContainer = {
  border: '1px solid #0000001A',
  borderRadius: '4px'
}

export const EmbeddedContentBannerBackground = {
  background: 'linear-gradient(90deg, #014886 0%, #01A1DF 100%)',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
	boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
  }
}

export const EmbeddedContentBannerContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '12px 24px'
}

export const EmbeddedContentBannerHeader = {
  fontSize: '32px',
  fontWeight: '800',
  letterSpacing: '-.5px',
  color: '#FFFFFF'
}