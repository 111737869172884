import { CustomContentCardData } from '../types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as Styles from 'features/braze/embedded-content-cards/EmbeddedContentCardStyles';

export interface EmbeddedContentBannerProps {
  contentCard: CustomContentCardData;
  triggerClickEvent: (CustomContentCardData) => void;
}

export const EmbeddedContentBanner = ({ contentCard, triggerClickEvent }: EmbeddedContentBannerProps) => {
  return (
	<Box
	  sx={Styles.EmbeddedContentBannerBackground}
	  onClick={() => {
		triggerClickEvent(contentCard);
		window.open(contentCard.url, '_blank');
	  }}
	>
	  <Box sx={Styles.EmbeddedContentBannerContainer}>
		<Stack spacing={1} direction="column">
		  <Typography variant='h1' sx={Styles.EmbeddedContentBannerHeader}>
			{contentCard.title}
		  </Typography>
		  <Typography variant='subtitle100' sx={{ color: '#67C7EF' }}>
			{contentCard.description}
		  </Typography>
		</Stack>
		<Typography variant='body1' sx={{ color: '#FFFFFF' }}>
		  {contentCard.linkText}
		</Typography>
	  </Box>
	</Box>
  )
}