import { CustomContentCardData } from '../types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as Styles from 'features/braze/embedded-content-cards/EmbeddedContentCardStyles';

export interface EmbeddedContentCardProps {
  contentCard: CustomContentCardData;
  triggerClickEvent: (CustomContentCardData) => void;
}

export const EmbeddedContentCard = ({ contentCard, triggerClickEvent }: EmbeddedContentCardProps) => {
	return (
	  <Box sx={Styles.EmbeddedContentCardContainer}>
		<Stack spacing={2} direction="row" sx={{ margin: '12px' }}>
		  <Box sx={Styles.IconBox} >
			<LightbulbOutlinedIcon htmlColor='white' sx={{ width: '32px' }} />
		  </Box>
		  <Box>
			<Typography variant="h3">
			  {contentCard.title}
			</Typography>
			<Typography variant='body1' sx={{ padding: '8px 0px' }}>
			  {contentCard.description}
			</Typography>
			<Link
			  href={contentCard.url}
			  target="_blank"
			  rel="noopener noreferrer"
			  variant='body1'
			  sx={{ borderBottom: 'none !important' }}
			  onClick={() => triggerClickEvent(contentCard)}
			>
			  {contentCard.linkText}
			</Link>
		  </Box>
		</Stack>
	  </Box>
	)
}